import { ThemeOptions } from '@mui/material';

export const webThemeOptions: ThemeOptions = {
  typography: {
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 700,
    htmlFontSize: 15,
    fontSize: 15,
    fontFamily: 'Montserrat',
    allVariants: {
      fontFamily: 'Montserrat',
    },
    h1: {
      fontFamily: 'Montserrat',
      fontSize: '3rem',
      fontWeight: 600,
      lineHeight: 'normal',
      letterSpacing: 'normal',
    },
    h2: {
      fontFamily: 'Montserrat',
      fontSize: '2.75rem',
      fontWeight: 700,
      lineHeight: 'normal',
      letterSpacing: 'normal',
    },
    h3: {
      fontFamily: 'Montserrat',
      fontSize: '2rem',
      fontWeight: 700,
      lineHeight: 'normal',
      letterSpacing: 'normal',
    },
    h4: {
      fontFamily: 'Montserrat',
      fontSize: '1.5rem',
      fontWeight: 700,
      lineHeight: 'normal',
      letterSpacing: 'normal',
    },
    h5: {
      fontFamily: 'Montserrat',
      fontSize: '1.25rem',
      fontWeight: 700,
      lineHeight: 'normal',
      letterSpacing: 'normal',
    },
    h6: {
      fontFamily: 'Montserrat',
      fontSize: '1rem',
      fontWeight: 600,
      lineHeight: 'normal',
      letterSpacing: 'normal',
    },
    body1: {
      fontFamily: 'Open Sans',
      fontSize: '1rem',
      fontWeight: 400,
      lineHeight: 'normal',
      letterSpacing: 'normal',
    },
    body2: {
      fontFamily: 'Open Sans',
      fontSize: '0.9375rem',
      fontWeight: 400,
      lineHeight: 'normal',
      letterSpacing: 'normal',
    },
  },
};
