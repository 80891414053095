/**
 * Get config from env
 *
 * @return {object} Configuration
 */
export function getConfig() {
  return {
    // AUTH0 Env variables
    AUTH0_TENANT_ISSUER_URL: process.env.AUTH0_TENANT_ISSUER_URL!,
    AUTH0_APP_CLIENT_ID: process.env.AUTH0_APP_CLIENT_ID!,
    AUTH0_APP_BASE_URL: process.env.AUTH0_APP_BASE_URL!,
    AUTH0_APP_CALLBACK_URL: process.env.AUTH0_APP_CALLBACK_URL!,
    AUTH0_APP_SCOPE: process.env.AUTH0_APP_SCOPE!,
    AUTH0_API_AUDIENCE: process.env.AUTH0_API_AUDIENCE!,

    // Backend Env variables
    API_BASE_URL: process.env.API_BASE_URL!,
    API_VERSION: process.env.API_VERSION!,

    // CMS Env variables
    BUILDER_API_KEY: process.env.BUILDER_API_KEY!,

    // RazorPay Env variables
    RAZORPAY_KEY: process.env.RAZORPAY_KEY!,

    // Google Maps API key
    GOOGLE_MAPS_PLATFORM_API_KEY: process.env.GOOGLE_MAPS_PLATFORM_API_KEY,

    GTM_ID: process.env.GOOGLE_TAG_MANAGER_ID,

    TYPEFORM_ID_FREE_SESSION: process.env.TYPEFORM_ID_FREE_SESSION,

    AUTH0_CACHE_LOCATION: process.env.AUTH0_CACHE_LOCATION!,
  };
}
