import React from 'react';

const ServerErrorIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={148}
    height={148.001}
  >
    <g data-name="Group 7306">
      <path
        data-name="Path 21628"
        d="M74 0a74 74 0 1 0 74 74A74 74 0 0 0 74 0zm0 141.636A67.639 67.639 0 1 1 141.636 74 67.713 67.713 0 0 1 74 141.636z"
        style={{
          fill: '#e5950b',
        }}
      />
      <path
        data-name="Path 21629"
        d="M79.574 15.363a64.216 64.216 0 1 0 64.212 64.217 64.29 64.29 0 0 0-64.212-64.217zM58.705 95.385q-3.449 4.229-10.318 4.227a13.685 13.685 0 0 1-9.084-2.97 10.544 10.544 0 0 1-3.339-5.066 2.671 2.671 0 0 1 2.808-3.361h.75c1.889 0 3.553.964 4.016 2.051a5.923 5.923 0 0 0 1.143 1.789 4.909 4.909 0 0 0 3.735 1.354 5.042 5.042 0 0 0 4.312-1.993 8.2 8.2 0 0 0 1.484-5.016 8.745 8.745 0 0 0-1.388-5.016 4.862 4.862 0 0 0-4.324-2.049 7.4 7.4 0 0 0-2.4.348 4.813 4.813 0 0 0-1.579.924 7.139 7.139 0 0 1-4.534 1.3A3.114 3.114 0 0 1 37 78.345l1.859-14.636a3.991 3.991 0 0 1 3.855-3.4h14.492a3.241 3.241 0 1 1 0 6.472h-8.99a4.172 4.172 0 0 0-3.979 3.379l-.28 1.722c-.3 1.866.03 2.986.784 2.555a9.778 9.778 0 0 1 1.414-.7 11.6 11.6 0 0 1 4.033-.617 12.125 12.125 0 0 1 8.389 3.237q3.578 3.235 3.578 9.416a14.8 14.8 0 0 1-3.45 9.612zm31.15-1.016q-2.99 5.191-10.392 5.193T69.07 94.369q-2.984-5.191-2.983-14.793t2.983-14.82q2.985-5.219 10.393-5.215t10.392 5.215q2.98 5.219 2.983 14.82t-2.983 14.793zm30.467 0q-2.985 5.191-10.39 5.193t-10.393-5.193q-2.99-5.191-2.982-14.793t2.982-14.82q2.98-5.219 10.394-5.215t10.39 5.215q2.984 5.219 2.982 14.82t-2.983 14.793z"
        transform="translate(-5.574 -5.577)"
        style={{
          fill: '#e5950b',
        }}
      />
      <path
        data-name="Path 21630"
        d="M160.638 94.584a4.376 4.376 0 0 0-4.495 3.371q-1.1 3.367-1.1 10.378a36.35 36.35 0 0 0 1.1 10.232q1.093 3.546 4.495 3.544t4.453-3.544a37.912 37.912 0 0 0 1.057-10.232 37.521 37.521 0 0 0-1.057-10.38 4.322 4.322 0 0 0-4.453-3.369z"
        transform="translate(-56.28 -34.333)"
        style={{
          fill: '#e5950b',
        }}
      />
      <path
        data-name="Path 21631"
        d="M112.806 94.584a4.374 4.374 0 0 0-4.493 3.371q-1.1 3.367-1.1 10.378a36.422 36.422 0 0 0 1.1 10.232q1.093 3.546 4.493 3.544t4.454-3.544a37.809 37.809 0 0 0 1.057-10.232 37.4 37.4 0 0 0-1.057-10.38 4.323 4.323 0 0 0-4.454-3.369z"
        transform="translate(-38.918 -34.333)"
        style={{
          fill: '#e5950b',
        }}
      />
    </g>
  </svg>
);

export default ServerErrorIcon;
