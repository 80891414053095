import { styled } from '@mui/material/styles';
import { Button, ButtonProps } from '@mui/material';

export const StyledComponents = {
  Wrapper: styled('div')({
    display: 'flex',
    flexFlow: 'column',
    height: '85vh',
  }),
  Root: styled('div')({
    position: 'relative',
    paddingTop: '32px',
    paddingBottom: '32px',
    color: 'white',
  }),
  Container: styled('div')({
    marginTop: '14vh',
    borderRadius: 12,
    display: 'flex',
    flexDirection: 'column',
    padding: '60px 30px',
    backdropFilter: 'brightness(0.8)',
  }),
  PageTitle: styled('div')({
    textAlign: 'center',
    padding: '18px auto',
    textTransform: 'uppercase',
    margin: '0 auto 22px auto',
  }),
  InfoSection: styled('div')({
    display: 'flex',
    justifyContent: 'center',
  }),
  BottomButton: styled(Button)<ButtonProps>({
    minWidth: '60%',
    paddingTop: '0.8rem',
    paddingBottom: '0.8rem',
    borderRadius: 8,
  }),
};
