import { useAuth0 } from '@auth0/auth0-react';
import CryptoJS from 'crypto-js';

import { useEffect } from 'react';
declare global {
  interface Window {
    // eslint-disable-next-line camelcase
    dataLayer: Array<{ event: string; user_id: string }>;
  }
}
/**
 *
 * @return {null}
 */
export default function TrackingEvents() {
  const { user } = useAuth0();

  useEffect(() => {
    console.info('Tracking events initiated');
    if (user?.email) {
      const userAuthStateChangeEvent = new CustomEvent('user-auth-state-change', {
        detail: {
          email: user.email,
        },
        bubbles: true,
        cancelable: false,
        composed: false,
      });
      document.dispatchEvent(userAuthStateChangeEvent);

      // track logged in and non-logged in users on platform

      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: user.loginCount === 1 ? 'userSignup' : 'userLogin',
        // eslint-disable-next-line new-cap
        user_id: CryptoJS.SHA256(user.email).toString(CryptoJS.enc.Hex),
      });
    }
  }, [user]);

  return null;
}
