import { styled } from '@mui/material/styles';

export const StyledComponents = {
  Container: styled('div')({
    height: '100%',
    width: '100%',
    position: 'relative',
  }),
  BgImage: styled('div')({
    position: 'absolute',
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    overflow: 'hidden',
    zIndex: 0,
  }),
};
