import React, { ReactElement } from 'react';
import type { AppProps } from 'next/app';
import Head from 'next/head';
import { ThemeProvider, Theme, StyledEngineProvider, useMediaQuery } from '@mui/material';
import { CacheProvider, EmotionCache } from '@emotion/react';
import router from 'next/router';
import createGenerateClassName from '@mui/styles/createGenerateClassName';
import StylesProvider from '@mui/styles/StylesProvider';
import { Auth0Provider, Auth0ProviderOptions, CacheLocation } from '@auth0/auth0-react';

import '../styles/globals.css';

import { mobileTheme, webTheme } from 'lib/theme';
import { getConfig } from '../config';
import { ErrorBoundary } from 'components/Common/ErrorBoundary';
import { createEmotionCache } from 'utils/emotion';
import TrackingEvents from 'components/Common/TrackingEvents';

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

const config = getConfig();

const auth0ProviderConfig: Auth0ProviderOptions = {
  domain: config.AUTH0_TENANT_ISSUER_URL,
  clientId: config.AUTH0_APP_CLIENT_ID,
  redirectUri: config.AUTH0_APP_CALLBACK_URL,
  scope: config.AUTH0_APP_SCOPE,
  audience: config.AUTH0_API_AUDIENCE,
  useRefreshTokens: true,
  cacheLocation: config.AUTH0_CACHE_LOCATION as CacheLocation,
  onRedirectCallback: (appState) => {
    const returnToParts = appState?.returnTo?.split('?');
    const pathname = returnToParts?.[0];
    const query = returnToParts?.slice(1).join('?');
    router.replace({ pathname, query });
  },
};

const clientSideEmotionCache = createEmotionCache();

const generateClassName = createGenerateClassName({
  productionPrefix: 'jss',
  seed: '',
});

interface CustomAppProps extends AppProps {
  emotionCache: EmotionCache;
}

/**
 * The root of the next app.
 * @param {AppProps} param0
 * @return {ReactElement}
 */
function App({ Component, pageProps, emotionCache = clientSideEmotionCache }: CustomAppProps): ReactElement {
  const isMobile = useMediaQuery('(max-width: 600px)');

  return (
    <Auth0Provider {...auth0ProviderConfig}>
      <CacheProvider value={emotionCache}>
        <StyledEngineProvider>
          <StylesProvider generateClassName={generateClassName}>
            <ThemeProvider theme={isMobile ? mobileTheme : webTheme}>
              <Head>
                <meta
                  name="viewport"
                  content="width=device-width, initial-scale=1"
                />
                <link
                  rel="icon"
                  href="/SYMBOL_ICON.png"
                  type="image/png"
                />
              </Head>
              <div id="app_content">
                <ErrorBoundary showInLayout={true}>
                  <Component {...pageProps} />
                </ErrorBoundary>
              </div>
            </ThemeProvider>
          </StylesProvider>
        </StyledEngineProvider>
      </CacheProvider>
      <TrackingEvents />
    </Auth0Provider>
  );
}

export default App;
