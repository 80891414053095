import { ReactElement } from 'react';
import { Container, Typography } from '@mui/material';

import BackgroundImage from 'components/Common/BackgroundImage';
import ServerErrorIcon from 'components/Icons/ServerError';

import bgImage from 'public/images/404-bg.png';
import { StyledComponents } from './styled';

/**
 * This page appears when the payment is done.
 * @return {ReactElement}
 */
export function ServerError({ fullHeight = false }): ReactElement {
  return (
    <div>
      <StyledComponents.Wrapper style={{ height: fullHeight ? '100vh' : undefined }}>
        <BackgroundImage
          src={bgImage}
          layout="fill"
        >
          <StyledComponents.Root>
            <Container
              maxWidth="md"
              style={{ maxWidth: 540 }}
            >
              <StyledComponents.Container>
                <StyledComponents.InfoSection>
                  <ServerErrorIcon />
                </StyledComponents.InfoSection>
                <div
                  style={{
                    textAlign: 'center',
                    margin: '30px 0',
                  }}
                >
                  <Typography
                    variant="h4"
                    style={{ textTransform: 'uppercase' }}
                  >
                    Internal Server Error
                  </Typography>
                  <Typography style={{ marginTop: 30 }}>
                    The server encountered an internal error or misconfiguration and was unable to complete your
                    request.
                  </Typography>
                </div>
                <StyledComponents.InfoSection>
                  <a href="/">
                    <StyledComponents.BottomButton
                      variant="contained"
                      color="primary"
                    >
                      Return Home
                    </StyledComponents.BottomButton>
                  </a>
                </StyledComponents.InfoSection>
              </StyledComponents.Container>
            </Container>
          </StyledComponents.Root>
        </BackgroundImage>
      </StyledComponents.Wrapper>
    </div>
  );
}
