import React, { ReactElement } from 'react';
import Image, { ImageProps } from 'next/image';
import { StyledComponents } from './styled';

type BgImageProps = ImageProps & {};

/**
 * This component renders background Image
 * The height and width of the image will be decided by the container component.
 * * The container component must have position: 'relative'
 *
 * @param {ImageProps} props
 * @return {ReactElement}
 */
export default function BackgroundImage(props: BgImageProps): ReactElement {
  const { children, ...imageProps } = props;

  return (
    <StyledComponents.Container>
      <StyledComponents.BgImage>
        <Image {...imageProps} />
      </StyledComponents.BgImage>
      <div>{children}</div>
    </StyledComponents.Container>
  );
}
