/* eslint-disable @typescript-eslint/no-empty-interface */
import { createTheme, ThemeOptions, css } from '@mui/material/styles';

import { mobileThemeOptions } from './mobile';
import { webThemeOptions } from './web';

const augmentColor = (color: string, contrastText?: string) =>
  createTheme().palette.augmentColor({ color: { main: color, contrastText } });

const baseThemeOptions: ThemeOptions = {
  palette: {
    // Primary refers to the blue action color.
    // blue:
    primary: augmentColor('#0099CC'),
    // Secondary refers to the purple action color.
    // purple:
    secondary: augmentColor('#77049d'),
    navy: augmentColor('#2d2c5c'),
    lavender: augmentColor('#c1c0e1'),
    yellow: augmentColor('#e5950b', '#fff'),
    pink: augmentColor('#dd004d'),
    lightBlue: augmentColor('#dbf4fc'),
    aliceBlue: augmentColor('#edfafe'),
    white: augmentColor('#fff'),
    black: augmentColor('#000'),
    whiteSmoke: augmentColor('#f7f7f8'),
    green: augmentColor('#34a853'),
    orange: augmentColor('#fbba05', '#fff'),
    red: augmentColor('#ea4335'),
    skyBlue: augmentColor('#4285f4'),
    text: {
      primary: '#2d2c5c',
    },
  },
  components: {
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
        style: {
          borderRadius: '8px',
        },
      },
      styleOverrides: {
        root: css`
          border-radius: 8px;
          font-size: 1rem;
          font-weight: 600;
          font-family: Montserrat;
        `,
      },
    },
    MuiButton: {
      defaultProps: {
        disableRipple: true,
        disableElevation: true,
      },
      styleOverrides: {
        root: css`
          border-radius: 8px;
          font-size: 1rem;
          font-weight: 600;
          font-family: Montserrat;
        `,
        text: css`
          font-size: 1rem;
          font-weight: 600;
          font-family: Montserrat;
        `,
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: css`
          border-radius: 4px;
        `,
      },
    },
    MuiSelect: {
      styleOverrides: {
        outlined: css`
          border-radius: 4px;
        `,
        filled: css`
          border-radius: 4px;
        `,
      },
    },
    MuiCard: {
      styleOverrides: {
        root: css`
          border-radius: 12px;
          box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.06);
          border: solid 1px #f5f2f2;
          padding: 25px;
        `,
      },
    },
    MuiChip: {
      styleOverrides: {
        root: css`
          border-radius: 13px;
        `,
      },
    },
  },
};

/**
 * Configures the default theme for Material UI.
 */
export const mobileTheme = createTheme(baseThemeOptions, mobileThemeOptions);
export const webTheme = createTheme(baseThemeOptions, webThemeOptions);

export enum GenleapThemeColorEnum {
  'primary' = 'primary',
  'secondary' = 'secondary',
  'navy' = 'navy',
  'lavender' = 'lavender',
  'yellow' = 'yellow',
  'pink' = 'pink',
  'lightBlue' = 'lightBlue',
  'aliceBlue' = 'aliceBlue',
  'white' = 'white',
  'black' = 'black',
  'whiteSmoke' = 'whiteSmoke',
  'green' = 'green',
  'orange' = 'orange',
  'red' = 'red',
}

export type GenleapThemeColors =
  | 'primary'
  | 'secondary'
  | 'navy'
  | 'lavender'
  | 'yellow'
  | 'pink'
  | 'lightBlue'
  | 'aliceBlue'
  | 'white'
  | 'black'
  | 'whiteSmoke'
  | 'green'
  | 'orange'
  | 'red';

const _CMSThemeColorsList = ['navy', 'white', 'pink', 'yellow', 'light-blue', 'lavender', 'purple'] as const;
export const CMSThemeColorsList = _CMSThemeColorsList as unknown as string[];

export type CMSThemeColors = typeof CMSThemeColorsList[number];
