/* eslint-disable require-jsdoc */
import React from 'react';

import { ServerError } from 'components/Common/ServerError';
import { FooterContent } from 'services/cms/index.d';
import { IItemWithSubMenu } from 'components/Common/Navbar';

type Props = {
  showInLayout: boolean;
};

export class ErrorBoundary extends React.Component<
  Props,
  {
    hasError: boolean;
    navItems?: IItemWithSubMenu[];
    footerContent?: FooterContent;
  }
> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  render() {
    const state = this.state;

    if (state.hasError) {
      // You can render any custom fallback UI
      return (
        <div
          id="error-boundary"
          data-testid="error-boundary"
        >
          <ServerError fullHeight={true} />
        </div>
      );
    }

    return this.props.children;
  }
}
